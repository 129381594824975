import React from 'react';
import logo from '../assets/images/logo.svg';

export default function Footer() {
  return (
    <div className="max-w-[1200px] mx-auto px-6 mt-16">
      <div className=" mb-8">
        <div className="block justify-between items-center">
          <div data-aos="zoom-in-right" data-aos-delay="300" data-aos-duration="750">
            <div className="flex justify-center gap-3">
              <img src={logo} alt={logo} />
              <div className="font-Caveat text-4xl flex items-center">CopsDorkL</div>
            </div>
          </div>
          <div
            data-aos="fade-down"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="300"
            data-aos-duration="750"
          >
            <p className="mt-5 font-Monda opacity-80 text-lg leading-[30px] text-center ">
              Copyright © COPS DORKL 2023. <br /> All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
