import './App.css';
import { useState } from 'react';
import Home from './pages/Home';
import Header from './components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';

function App() {
  Aos.init();
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-black text-white" id="home">
      <div className="flex flex-col font-monda">
        <BrowserRouter>
          <Header setOpen={setOpen} headerOpen={open} />
          <div className={`bg-image flex-1 ${open ? 'hidden' : 'block'}`}>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
