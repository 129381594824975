import React from 'react';
import pp from '../assets/images/pp.png';

export default function PepePolice() {
  return (
    <div className="max-w-[1200px] mx-auto px-6">
      <div
        className="-mt-24 sm:-mt-10  "
        data-aos="fade-up"
        data-aos-delay="600"
        data-aos-duration="500"
      >
        <img src={pp} alt="pp" />
      </div>
    </div>
  );
}
