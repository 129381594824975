import React from 'react';

export default function Tokenomics() {
  return (
    <div className="max-w-[1200px] mx-auto px-6" id="tokenomics">
      <div className="mt-9 sm:mt-12 md:mt-36">
        <div
          className="text-center font-Caveat text-7xl md:text-8xl lg:text-9xl leading-[72px] "
          data-aos="fade-up"
          data-aos-delay="1000"
          data-aos-duration="500"
        >
          Tokenomics
        </div>
      </div>
      <div className="mt-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
            <div className="font-Caveat text-2xl leading-10 text-center ">Token Supply</div>
            <div className="text-[24px] leading-10 font-Salsa text-center mt-3">1,000,000,000</div>
          </div>
          <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="500">
            <div className="font-Caveat text-2xl leading-10 text-center">Tax</div>
            <div className="text-[24px] leading-10 font-Salsa text-center mt-3">1%</div>
          </div>
          <div data-aos="fade-up" data-aos-delay="700" data-aos-duration="500">
            <div className="font-Caveat text-2xl leading-10 text-center">Liqudity</div>
            <div className="text-[24px] leading-10 font-Salsa text-center mt-3">100% Burned</div>
          </div>
          <div data-aos="fade-up" data-aos-delay="900" data-aos-duration="500">
            <div className="font-Caveat text-2xl leading-10 text-center">Ownership</div>
            <div className="text-[24px] leading-10 font-Salsa text-center mt-3">Renounced</div>
          </div>

        </div>
      </div>
    </div>
  );
}
