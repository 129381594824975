import React from 'react';
import telegram from '../assets/images/telegram.png';
import twitter from '../assets/images/twitter.png';
import herosection_bg from '../assets/images/herosection_bg.png';

export default function HeroSection() {
  return (
    <div className=" max-w-[1200px] mx-auto  px-6">
      <div className="flex">
        <div className="w-full md:w-1/2 flex flex-col items-start gap-4 mt-16 animate__animated animate__backInLeft">
          <h2 className="font-Caveat text-7xl md:text-8xl lg:text-9xl">COPS DORKL</h2>
          <p className="font-Salsa text-2xl leading-10 ">
            This is DORK LORD Season! Did you miss PEPE season? If yes, Please don't miss CopsDorkL Today. DORK LORD is ultimate meme and good chance for becoming a millionaire.
            Don't miss chance and meet $CODL TODAY!
          </p>
          <div className="mt-12">
            <p className="flex font-Caveat text-[32px] leading-[32px] gap-2 text-white">
              CA:
              <span className="text-title-yellow w-[100px] sm:w-[100px] md:w-full text-ellipsis overflow-hidden">
                0x847feC068a092247DD0A6a83a7E2cD791bfFb156
              </span>
            </p>
          </div>
          <div className="mt-8 mb-14">
            <div className="flex gap-7 items-center">
              <div>
                <a
                  href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x847feC068a092247DD0A6a83a7E2cD791bfFb156"
                  target="_blank"
                >
                  <button className="font-Salsa button-bg text-white text-base leading-5 font-normal py-3 px-8 rounded-[50px] ">
                    Get $CODL
                  </button>
                </a>
              </div>
              <div>
                <a href="https://twitter.com/copsdorklord" target="_blank">
                  <img src={twitter} alt="twitter" className="cursor-pointer" />
                </a>
              </div>
              <div>
                <a href="https://t.me/copsdorklord" target="_blank">
                  <img src={telegram} alt="telegram" className="cursor-pointer" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 relative sm:pb-10">
          <div className="hidden sm:hidden md:block animate__animated   animate__fadeInRightBig ">
            <img
              src={herosection_bg}
              alt="punch"
              className="min-w-full md:scale-110 lg:scale-110"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
