import React from 'react';
import siren from '../assets/images/siren.png';

export default function AboutUs() {
  return (
    <div className="max-w-[1200px] mx-auto px-6" id="aboutus">
      <div className="my-[148px] ">
        <div className="block sm:block md:flex justify-between gap-4">
          <div className="w-full sm:w-full md:w-2/4">
            <p
              className="font-Caveat text-7xl md:text-8xl lg:text-9xl leading-[72px] "
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              What is?
            </p>
            <div className="mt-8">
              <p
                className="text-[24px] leading-10  font-Salsa "
                data-aos="flip-left"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                CopsDorkL is not just a meme coin, it’s a bodyguard of all meme coins. Cops DorkLord
                always try to protect all meme coins and keep safety from all scammers.
                Meet $CODL and ask what is scam. Don’t miss this opportunity to join
                the Cops DorkLord.
              </p>
            </div>
            <div className="mt-12 flex gap-5">
              <a
                href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x847feC068a092247DD0A6a83a7E2cD791bfFb156"
                target="_blank"
              >
                <button
                  className="font-Salsa button-bg text-white text-base leading-5 font-normal py-3 px-8 rounded-[50px] "
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="500"
                >
                  Buy Now
                </button>
              </a>
            </div>
          </div>
          <div
            className="w-full sm:w-full md:w-2/4 lg:w-2/4 mt-5 sm:mt-5 md:mt-0"
            data-aos="fade-up"
            data-aos-delay="1000"
            data-aos-duration="5000"
          >
            <div>
              <img src={siren} alt="master_punch" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
