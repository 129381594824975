import React from 'react';
import AboutUs from '../components/AboutUs';
import Tokenomics from '../components/Tokenomics';
import PepePolice from '../components/PepePolice';
import RoadMap from '../components/RoadMap';

export default function Home() {
  return (
    <div id="home">
      <AboutUs />
      <PepePolice />
      <Tokenomics />
      {/* <RoadMap /> */}
    </div>
  );
}
